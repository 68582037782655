import React, {useEffect, useState} from 'react';
import '../assets/css/main.css'
import { LngArrow, RuIcon} from "../assets/svg/LanguageIcons";
import {BrainIcon, EnergyIcon, LapkaIcon, Question1Icon} from "../assets/svg/icons";
import {useTelegram} from "../TelegramContext";
import Logo from "../assets/img/logo.svg";
import Menu1 from "../assets/img/menu1.svg";
import Menu2 from "../assets/img/menu2.svg";
import Menu3 from "../assets/img/menu3.svg";
import Menu4 from "../assets/img/menu4.svg";
import SpriteIcon from "../assets/img/sprite.svg";
import NotificationIcon from "../assets/img/svg/notification.svg";
import { LinkProps, Link as RouterLink } from "react-router-dom";

function Header({userData,staminaMin,staminaMax,staminaLevel}) {

    const { user, triggerVibration } = useTelegram();

    const [isAnimating, setIsAnimating] = useState(false);


    return (
        <>
            <header className="header">
                <div className="header__row">
                    <a href="#" className="header__action">
                        <img src={SpriteIcon} alt=""/>
                        {/*<svg class="header__action-icon">*/}
                        {/*    <use xlink:href="img/sprite.svg#person"></use>*/}
                        {/*</svg>*/}
                    </a>
                    <RouterLink to={'/'} className="header__logo">

                        <div className="logo">

                            <img className="logo__icon" src={Logo} alt="logo bobster"/>
                            <div className="logo__text">
                                <div className="logo__name">BOBSTER</div>
                                <div className="logo__descr">PAYMENTS</div>
                            </div>
                        </div>

                    </RouterLink>

                    <RouterLink to={'/notifications'} href="#" className="header__action">

                        <img src={NotificationIcon} alt=""/>

                    </RouterLink>
                </div>
            </header>
            <nav className="navigation">
                <ul className="navigation__list">
                    <li className="navigation__item">
                        <RouterLink to={'/'} className="navigation__link">

                            <img src={Menu1} alt=""/>
                            <p className="navigation__link-text">Перевести</p>
                        </RouterLink>
                    </li>
                    <li className="navigation__item">
                        <RouterLink to={'/chats'} className="navigation__link">

                            <img src={Menu2} alt=""/>

                            <p className="navigation__link-text">Поддержка</p>
                        </RouterLink>
                    </li>
                    <li className="navigation__item">
                        <RouterLink to={'/story'}  className="navigation__link">
                            <img src={Menu3} alt=""/>
                            <p className="navigation__link-text">История</p>
                        </RouterLink>
                    </li>
                    <li className="navigation__item">
                        <RouterLink to={'/settings'} className="navigation__link">
                            <img src={Menu4} alt=""/>
                            <p className="navigation__link-text">Настройки</p>
                        </RouterLink>
                    </li>
                </ul>
            </nav>
        </>
    );
}

export default Header;
