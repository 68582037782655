import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import { useNavigate } from "react-router-dom";
import '../../assets/css/main.css'; // Assuming you have a CSS file for styles

import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../utils/constants';

import Menu1 from '../../assets/img/menu1.svg'
import Menu2 from '../../assets/img/menu2.svg'
import Menu3 from '../../assets/img/menu3.svg'
import Menu4 from '../../assets/img/menu4.svg'
import Logo from '../../assets/img/logo.svg'
import HelpIcon from '../../assets/img/svg/help.svg'
import RubIcon from '../../assets/img/currency/rub.svg'
import ArrowIcon from '../../assets/img/svg/arrow.svg'
import LiraIcon from '../../assets/img/currency/lira.svg'
import RusIcon from '../../assets/img/country/rus.svg'
import QrIcon from '../../assets/img/qr.jpg'
import MastercardIcon from '../../assets/img/card-icons/mastercard.svg'
import VisaIcon from '../../assets/img/card-icons/visa.svg'
import AddIcon from '../../assets/img/svg/add.svg'

import Header from "../../components/Header";

function MyCards() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();

    // if (loading || loadingAssets || loadingPriceCurrencies) {
    //     return (
    //         <Loader/>
    //     )
    // }

    return (
        <div class="wrapper">

            <div class="page ">
                <Header/>
                <div class="main cards">
                    <div class="body">
                        <h1 class="cards__title title mb-title">Мои карты</h1>
                        <ul class="cards__items">
                            <li class="cards__item">
                                <label for="" class="cards__label">Для TRY</label>
                                <div class="cards__body">
                                    <img class="cards__icon" src={MastercardIcon} alt=""/>
                                        <div class="cards__info">
                                            <div class="cards__code">2589 **** ***** 1111</div>
                                            <div class="cards__term">11/26</div>
                                        </div>
                                        <button class="cards__btn-del">
                                            {/*<svg class="cards__btn-del-icon">*/}
                                            {/*    <use xlink:href="img/sprite.svg#trash"></use>*/}
                                            {/*</svg>*/}
                                        </button>
                                </div>
                            </li>
                            <li class="cards__item">
                                <label for="" class="cards__label">Для CNY</label>
                                <div class="cards__body">
                                        <img class="cards__icon" src={MastercardIcon} alt=""/>
                                        <div class="cards__info">
                                            <div class="cards__code">2589 **** ***** 1111</div>
                                            <div class="cards__term">11/26</div>
                                        </div>
                                        <button class="cards__btn-del">
                                            {/*<svg class="cards__btn-del-icon">*/}
                                            {/*    <use xlink:href="img/sprite.svg#trash"></use>*/}
                                            {/*</svg>*/}
                                        </button>
                                </div>
                            </li>
                            <li class="cards__item">
                                <label for="" class="cards__label">Для USD</label>
                                <div class="cards__body">
                                    <img class="cards__icon" src={VisaIcon} alt="" />
                                    <div class="cards__info">
                                        <div class="cards__code">2589 **** ***** 1111</div>
                                        <div class="cards__term">11/26</div>
                                    </div>
                                    <button class="cards__btn-del">
                                        {/*<svg class="cards__btn-del-icon">*/}
                                        {/*    <use xlink:href="img/sprite.svg#trash"></use>*/}
                                        {/*</svg>*/}
                                    </button>
                                </div>
                            </li>
                        </ul>
                        <RouterLink to={'/add-card'} class="cards__add-btn button button--blue-border">Добавить
                            <img class="cards__add-btn-icon" src={AddIcon} alt="" />
                        </RouterLink>
                    </div>
                    <div class="footer">
                        <button class="button">Сохранить изменения</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyCards;
