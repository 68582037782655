import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import { useNavigate } from "react-router-dom";
import '../../../assets/css/main.css'; // Assuming you have a CSS file for styles

import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../../utils/constants';

import LogoIcon from '../../../assets/img/logo.svg'
import PdfIcon from '../../../assets/img/svg/pdf.svg'
import DoneIcon from '../../../assets/img/svg/done.svg'


import Header from "../../../components/Header";

function PaymentStep5Completed() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();

    // if (loading || loadingAssets || loadingPriceCurrencies) {
    //     return (
    //         <Loader/>
    //     )
    // }

    return (
        <div class="wrapper">

            <div class="page ">

                <Header/>
                <form class="main deal-done">
                    <div class="body">

                        <div class="deal-done__done done">
                            <img class="done__icon" src={DoneIcon} alt=""/>
                                <p class="done__text">Сделка по переводу <span class="done__text-code">#457664</span> завершена</p>
                        </div>
                        <div class="deal-done__rating rating">
                            <h2 class="deal-done__rating-title">Оцените сделку</h2>
                            <div class="rating__items">
                                <input class="rating__item" type="radio" name="rating" value="5" id="5" />
                                <label class="rating__label" for="5"></label>
                                <input class="rating__item" type="radio" name="rating" value="4" id="4" />
                                <label class="rating__label" for="4"></label>
                                <input class="rating__item" type="radio" name="rating" value="3" id="3" checked />
                                <label class="rating__label" for="3"></label>
                                <input class="rating__item" type="radio" name="rating" value="2" id="2" />
                                <label class="rating__label" for="2"></label>
                                <input class="rating__item" type="radio" name="rating" value="1" id="1" />
                                <label class="rating__label" for="1"></label>
                            </div>
                        </div>
                        <div class="deal-done__review review">
                            <ul class="review__items">
                                <input class="review__item" type="checkbox" name="review" value="fast-trader" id="fast-trader" checked />
                                <label class="review__label" for="fast-trader">Быстрый трейдер</label>
                                <input class="review__item" type="checkbox" name="review" value="safely" id="safely" />
                                <label class="review__label" for="safely">Безопасно</label>
                                <input class="review__item" type="checkbox" name="review" value="fast" id="fast" />
                                <label class="review__label" for="fast">Быстро</label>
                                <input class="review__item" type="checkbox" name="review" value="good-price" id="good-price" />
                                <label class="review__label" for="good-price">Хорошая цена</label>
                                <input class="review__item" type="checkbox" name="review" value="fast-transaction"
                                       id="fast-transaction" />
                                <label class="review__label" for="fast-transaction">Быстрая транзакция</label>
                            </ul>
                        </div>
                        <div class="deal-done__feedback feedback">
                            <textarea name="comments" class="feedback__textarea" placeholder="Введите ваш отзыв..."></textarea>
                            <div class="feedback__count">25/500</div>
                        </div>
                    </div>
                    <div class="footer">
                        <button type="submit" class="button">Отправить отзыв</button>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default PaymentStep5Completed;
