import React, { useState } from 'react';
import { useTelegram } from '../../TelegramContext';
import { useNavigate } from "react-router-dom";
import '../../assets/css/main.css'; // Assuming you have a CSS file for styles

import { LinkProps, Link as RouterLink } from "react-router-dom";

import Header from "../../components/Header";

function ChooseRecvisitType() {
    const { user, triggerVibration, webApp } = useTelegram();
    const navigate = useNavigate();

    const [selectedType, setSelectedType] = useState('card'); // Установим начальное значение 'card'

    const handleRadioChange = (event) => {
        setSelectedType(event.target.value); // Обновляем состояние при изменении выбора
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Выбранный тип реквизитов:", selectedType);
        // Здесь можно реализовать дальнейшую логику
    };

    return (
        <div className="wrapper">
            <div className="page">
                <Header/>
                <form className="details-type" onSubmit={handleSubmit}>
                    <div className="body">
                        <h1 className="details-type__title title">Выбор типа реквизитов</h1>
                        <div className="details-type__options options">
                            <div className="options__item">
                                <input
                                    id="iban"
                                    type="radio"
                                    name="details-type"
                                    value="iban"
                                    className="options__input"
                                    checked={selectedType === 'iban'}
                                    onChange={handleRadioChange}
                                />
                                <label htmlFor="iban" className="options__label">IBAN</label>
                            </div>
                            <div className="options__item">
                                <input
                                    id="card"
                                    type="radio"
                                    name="details-type"
                                    value="card"
                                    className="options__input"
                                    checked={selectedType === 'card'}
                                    onChange={handleRadioChange}
                                />
                                <label htmlFor="card" className="options__label">Карта</label>
                            </div>
                        </div>
                    </div>

                    <div className="footer">
                        {selectedType == 'card' ?

                            <RouterLink to={'/add-card'} type="submit" className="button">Продолжить</RouterLink>
                            :
                            <RouterLink to={'/add-iban'} type="submit" className="button">Продолжить</RouterLink>
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ChooseRecvisitType;
