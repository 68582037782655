import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import { useNavigate } from "react-router-dom";
import '../../../assets/css/main.css'; // Assuming you have a CSS file for styles

import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../../utils/constants';

import Menu1 from '../../../assets/img/menu1.svg'
import LogoIcon from '../../../assets/img/logo.svg'
import Menu2 from '../../../assets/img/menu2.svg'
import Menu3 from '../../../assets/img/menu3.svg'
import Menu4 from '../../../assets/img/menu4.svg'
import Logo from '../../../assets/img/logo.svg'
import HelpIcon from '../../../assets/img/svg/help.svg'
import RubIcon from '../../../assets/img/currency/rub.svg'
import ArrowIcon from '../../../assets/img/svg/arrow.svg'
import LiraIcon from '../../../assets/img/currency/lira.svg'
import RusIcon from '../../../assets/img/country/rus.svg'
import QrIcon from '../../../assets/img/qr.jpg'
import MastercardIcon from '../../../assets/img/card-icons/mastercard.svg'
import VisaIcon from '../../../assets/img/card-icons/visa.svg'
import GuaranteeIcon from '../../../assets/img/svg/guarantee.svg'

import Header from "../../../components/Header";

function PaymentStep1Loader() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();

    // if (loading || loadingAssets || loadingPriceCurrencies) {
    //     return (
    //         <Loader/>
    //     )
    // }

    return (
        <div class="wrapper">

            <div class="page ">
                <Header/>

                <div class="main client-transaction-detail">

                    <div class="body">
                        <button class="help">
                            <img class="help__icon" src={HelpIcon} alt=""/>
                                <p class="help__text">Помощь</p>
                        </button>

                        <div class="client-transaction-detail__info transaction-detail-info">
                            <h1 class="transaction-detail-info__title title">Детали перевода</h1>
                            <p class="transaction-detail-info__text">
                                Переведите <span class="transaction-detail-info__value">30 000 RUB</span> на указанную карту и
                                подтвердите
                                отправку
                            </p>
                            <div class="transaction-detail-info__code">
                                <div class="transaction-detail-info__code-value">2398 0293 0983 0983</div>
                                <div class="transaction-detail-info__code-actions">
                                    <button class="copy-btn">
                                        {/*<svg class="copy-btn__icon copy-btn__icon--white">*/}
                                        {/*    <use xlink:href="img/sprite.svg#copy"></use>*/}
                                        {/*</svg>*/}
                                    </button>
                                    <button class="link-btn">
                                        {/*<svg class="link-btn__icon link-btn__icon--orange">*/}
                                        {/*    <use xlink:href="img/sprite.svg#link"></use>*/}
                                        {/*</svg>*/}
                                    </button>
                                </div>
                            </div>
                            <div class="transaction-detail-info__bottom">
                                <div class="transaction-detail-info__bottom-item">
                                    <label class="transaction-detail-info__bottom-label">Имя получателя:</label>
                                    <p class="transaction-detail-info__bottom-text">Иван Иванович И.</p>
                                </div>
                                <div class="transaction-detail-info__bottom-item">
                                    <label class="transaction-detail-info__bottom-label">Банк:</label>
                                    <p class="transaction-detail-info__bottom-text">T-Банк</p>
                                </div>
                            </div>
                        </div>
                        <div class="client-transaction-detail__summary transaction-detail-summary">
                            <div class="transaction-detail-summary__amount">10 000 TRY</div>
                            <p class="transaction-detail-summary__text">Перевод будет зачислен на карту
                                <span> 1234 1234 1234 8888</span></p>
                        </div>
                        <div class="client-transaction-detail__time transaction-detail-time">
                            <p class="transaction-detail-time__text">Время до анулирования заявки</p>
                            <div class="transaction-detail-time__timer">13:45:31</div>
                        </div>
                    </div>
                    <div class="footer">
                        <RouterLink to={'/payment_step3_loader_transaction_details'} type="submit" class="button">Я оплатил</RouterLink>
                        <div class="guarantee">
                            <img class="guarantee__icon" src={GuaranteeIcon} alt=""/>
                                <p class="guarantee__text">Сделка гарантирована Bobster</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentStep1Loader;
