import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import { useNavigate } from "react-router-dom";
import '../../assets/css/main.css'; // Assuming you have a CSS file for styles

import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../utils/constants';

import Menu1 from '../../assets/img/menu1.svg'
import Menu2 from '../../assets/img/menu2.svg'
import Menu3 from '../../assets/img/menu3.svg'
import Menu4 from '../../assets/img/menu4.svg'
import Logo from '../../assets/img/logo.svg'
import HelpIcon from '../../assets/img/svg/help.svg'
import RubIcon from '../../assets/img/currency/rub.svg'
import ArrowIcon from '../../assets/img/svg/arrow.svg'
import LiraIcon from '../../assets/img/currency/lira.svg'
import RusIcon from '../../assets/img/country/rus.svg'
import QrIcon from '../../assets/img/qr.jpg'
import MastercardIcon from '../../assets/img/card-icons/mastercard.svg'
import VisaIcon from '../../assets/img/card-icons/visa.svg'
import AddIcon from '../../assets/img/svg/add.svg'

import Header from "../../components/Header";

function AddIBAN() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();

    // if (loading || loadingAssets || loadingPriceCurrencies) {
    //     return (
    //         <Loader/>
    //     )
    // }

    return (
        <div class="wrapper">

            <div class="page ">

                <Header/>

                <form class="main entering-details">
                    <div class="body">
                        <h1 class="title mb-title">Добавить IBAN</h1>
                        <div class="entering-details__form form">
                            <div class="entering-details__form-items form__items">
                                <div class="form__item">
                                    <label for="" class="form__label">IBAN</label>
                                    <div class="form-control">
                                        <input class="form-control__input" type="number" value="258987897897564564564"/>
                                    </div>
                                </div>
                                <div class="form__item">
                                    <label for="" class="form__label">Валюта</label>
                                    <button class="form__control form-control">
                                        <div class="form-control__img form-control__img--currency">
                                            <img class="form-control__icon" src={RubIcon} alt="rub" />
                                        </div>
                                        <p class="form-control__text">RUB <span class="form-control__text-currency">(Российский рубль)</span></p>
                                        <img class="form-control__arrow" src={ArrowIcon} alt="arrow" />
                                    </button>
                                </div>
                                <div class="form__item">
                                    <label for="" class="form__label">Имя</label>
                                    <input class="form-control" type="text" value="Иван" />
                                </div>
                                <div class="form__item">
                                    <label for="" class="form__label">Фамилия</label>
                                    <input class="form-control" type="text" value="Иванов" />
                                </div>
                                <div class="form__item">
                                    <label for="" class="form__label">Тег</label>
                                    <input class="form-control" type="text" value="тег" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer">
                        <RouterLink to={'/'} type="submit" class="button">Добавить</RouterLink>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddIBAN;
