import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import { useNavigate } from "react-router-dom";
import '../../../assets/css/main.css'; // Assuming you have a CSS file for styles

import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../../utils/constants';

import Menu1 from '../../../assets/img/menu1.svg'
import LogoIcon from '../../../assets/img/logo.svg'
import Menu2 from '../../../assets/img/menu2.svg'
import Menu3 from '../../../assets/img/menu3.svg'
import Menu4 from '../../../assets/img/menu4.svg'
import Logo from '../../../assets/img/logo.svg'
import HelpIcon from '../../../assets/img/svg/help.svg'
import RubIcon from '../../../assets/img/currency/rub.svg'
import ArrowIcon from '../../../assets/img/svg/arrow.svg'
import LiraIcon from '../../../assets/img/currency/lira.svg'
import RusIcon from '../../../assets/img/country/rus.svg'
import QrIcon from '../../../assets/img/qr.jpg'
import MastercardIcon from '../../../assets/img/card-icons/mastercard.svg'
import VisaIcon from '../../../assets/img/card-icons/visa.svg'
import AddIcon from '../../../assets/img/svg/add.svg'

import Header from "../../../components/Header";

function PaymentStep1Loader() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();

    // if (loading || loadingAssets || loadingPriceCurrencies) {
    //     return (
    //         <Loader/>
    //     )
    // }

    return (
        <div class="wrapper">

            <div class="page ">

                <Header/>
                <div class="main client-main-load">
                    <div class="body">
                        <h1 class="client-main-load__title title">Ожидание</h1>
                        <div class="client-main-load__body">
                            <img class="client-main-load__logo" src={LogoIcon}></img>
                            <div class="client-main-load__progress progress-load">

                                <div class="progress-load__item progress-completed">
                                    <div class="progress-load__item-circle">
                                        <div class="progress-load__item-icon">
                                        </div>
                                        <p class="progress-load__item-text">Создаем
                                            транзакцию</p>
                                    </div>
                                    <div class="progress-load__item-line"></div>
                                </div>

                                <div class="progress-load__item progress-active">
                                    <div class="progress-load__item-circle">
                                        <div class="progress-load__item-icon">
                                        </div>
                                        <p class="progress-load__item-text">Сверяем
                                            информацию</p>
                                    </div>
                                    <div class="progress-load__item-line"></div>
                                </div>

                                <div class="progress-load__item">
                                    <div class="progress-load__item-circle">
                                        <div class="progress-load__item-icon">
                                        </div>
                                        <p class="progress-load__item-text">Подтверждаем
                                            получателя</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <RouterLink to={'/payment_step2_loader_transaction_details'} class="footer">
                        <button class="button disable">Подождите еще чуть-чуть</button>
                    </RouterLink>
                </div>
            </div>
        </div>
    );
}

export default PaymentStep1Loader;
