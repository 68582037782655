import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import { useNavigate } from "react-router-dom";
import '../../../assets/css/main.css'; // Assuming you have a CSS file for styles

import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../../utils/constants';

import LogoIcon from '../../../assets/img/logo.svg'
import PdfIcon from '../../../assets/img/svg/pdf.svg'


import Header from "../../../components/Header";

function PaymentStep4Check() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();

    // if (loading || loadingAssets || loadingPriceCurrencies) {
    //     return (
    //         <Loader/>
    //     )
    // }

    return (
        <div className="wrapper">
            <div className="page ">

                <Header/>

                <div className="main check">
                    <div className="body">
                        <div className="check__body">
                            <img className="check__logo" src={LogoIcon} alt="logo bobster"/>

                            <p className="check__text">Трейдер уведомил о переводе. <br/>
                                Проверьте зачисление</p>
                            <div className="check__actions">
                                <a href="#" className="check__link">Чек о переводе</a>
                                <button className="check__btn-pdf">
                                    <img src={PdfIcon} alt=""/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <p className="check__important">Обязательно подтвердите перевод</p>
                        <div className="check__buttons">
                            <RouterLink to={'/payment_order_chat'} className="button button--blue-border">Перевод не получен</RouterLink>
                            <RouterLink to={'/payment_step5_completed'} className="button">Перевод получен</RouterLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentStep4Check;
