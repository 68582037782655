import React, { useEffect, useRef } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTelegram } from '../src/TelegramContext/index';
import {TonConnectUIProvider} from '@tonconnect/ui-react';


import Main from './pages/Main';
import Chats from './pages/Chats';
import Story from './pages/Story';
import Settings from './pages/Settings';
import MyCards from './pages/MyCards';
import AddCard from './pages/AddCard';
import ChooseRecvisitType from './pages/ChooseRecvisitType';
import AddIBAN from './pages/AddIBAN';
import ChatSupport from './pages/ChatSupport';
import Notifications from './pages/Notifications';


// Cards payment
import CardPaymentStep1Loader from './pages/Payment/PaymentStep1Loader';
import CardPaymentStep2TransactionDetails from './pages/Payment/PaymentStep2TransactionDetails';
import CardPaymentStep3TransactionDetails from './pages/Payment/PaymentStep3TransactionDetails';
import CardPaymentStep4Check from './pages/Payment/PaymentStep4Check';
import CardPaymentStep5Completed from './pages/Payment/PaymentStep5Completed';
import CardPaymentOrderChat from './pages/Payment/PaymentOrderChat';
import PaymentEnteringDetailsCash from './pages/Payment/PaymentEnteringDetailsCash';

function App() {
    const { user, webApp } = useTelegram();
    const [loader, setLoader] = React.useState(true);
    const [show2FaPage, setShow2FaPage] = React.useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const [connectSocket, setConnectSocket] = React.useState(false);
    const [socket, setSocket] = React.useState(null);

    const isMounted = useRef(false);

    // useEffect(() => {
    //
    //     if (process.env.REACT_APP_DEBUG == 'true') {
    //         if (!isMounted.current) {
    //             console.log(user, 'user------')
    //             checkUser();
    //             isMounted.current = true;
    //         }
    //     } else {
    //         // PROD
    //         if (webApp?.initData && webApp?.initData != '') {
    //
    //             if (webApp?.initData) {
    //                 checkUser();
    //             }
    //         }
    //     }
    //
    // }, [webApp?.initData]);

    // const getDeviceType = () => {
    //     const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    //     if (/android/i.test(userAgent)) {
    //         return "mobile";
    //     }
    //     if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //         return "mobile";
    //     }
    //     return "desktop";
    // };


    // const checkUser = async () => {
    //
    //
    //     const {error, data} = await getAccount(null, webApp?.initData);
    //
    //     if (error) {
    //
    //         console.log(window.location, 'window.location')
    //
    //         if (error?.kind == "AUTH_OTP_REQUIRED_ERR" && window.location?.pathname != '/settings-2fa') {
    //             // navigate('/settings-2fa?type=auth')
    //             window.location.href = 'settings-2fa?type=auth'
    //         }
    //         setLoader(false)
    //
    //         console.log('ERRROR!', error);
    //         return ;
    //     }
    //
    //     console.log(data, 'data')
    //
    //     setTimeout(()=>{
    //         setLoader(false)
    //     }, 1000)
    // };

    // if (loader) {
    //     return (
    //         <Loader/>
    //     )
    // }


    return (
        <TonConnectUIProvider manifestUrl="https://mindl.in/tonconnect-manifest.json">
            <Routes>
                <>
                    <Route path="/" element={<Main />} />
                    <Route path="/chats" element={<Chats />} />
                    <Route path="/story" element={<Story />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/my-cards" element={<MyCards />} />
                    <Route path="/add-card" element={<AddCard />} />
                    <Route path="/сhoose-recvisit-type" element={<ChooseRecvisitType />} />
                    <Route path="/add-iban" element={<AddIBAN />} />
                    <Route path="/chat-support" element={<ChatSupport />} />
                    <Route path="/notifications" element={<Notifications />} />

                    <Route path="/payment_step1_loader" element={<CardPaymentStep1Loader />} />
                    <Route path="/payment_step2_loader_transaction_details" element={<CardPaymentStep2TransactionDetails />} />
                    <Route path="/payment_step3_loader_transaction_details" element={<CardPaymentStep3TransactionDetails />} />
                    <Route path="/payment_step4_check" element={<CardPaymentStep4Check />} />
                    <Route path="/payment_step5_completed" element={<CardPaymentStep5Completed />} />
                    <Route path="/payment_order_chat" element={<CardPaymentOrderChat />} />

                    <Route path="/payment_entering_details_cash" element={<PaymentEnteringDetailsCash />} />


                </>

            </Routes>
        </TonConnectUIProvider>
    );
}

export default App;
