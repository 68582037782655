import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import { useNavigate } from "react-router-dom";
import '../../../assets/css/main.css'; // Assuming you have a CSS file for styles

import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../../utils/constants';

import LogoIcon from '../../../assets/img/logo.svg'
import PdfIcon from '../../../assets/img/svg/pdf.svg'
import DoneIcon from '../../../assets/img/svg/done.svg'
import CheckMesIcon from '../../../assets/img/svg/check-mes.svg';

import SmileIcon from '../../../assets/img/svg/smile.svg';
import MentionIcon from '../../../assets/img/svg/mention.svg';

import Header from "../../../components/Header";

function PaymentOrderChat() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();

    // if (loading || loadingAssets || loadingPriceCurrencies) {
    //     return (
    //         <Loader/>
    //     )
    // }

    return (
        <div className="wrapper">
            <div class="page ">

                <Header/>

                <div class="main chat">
                    <div class="body">

                        <h1 class="chat__title title">Чат поддержки по  ордеру #457664</h1>
                        <div class="chat__date">8/20/2020</div>
                        <div class="chat__items">
                            <div class="chat__messages">
                                <div class="chat__message chat__message--user">
                                    <div class="chat__message-text">Что за скам? Ничего не пришло. Оплатил 20 мин назад!</div>
                                    <div class="chat__message-time chat__message-time--user">11:35 AM
                                        <div class="chat__message-check read">
                                            <img class="chat__message-check-icon" src={CheckMesIcon} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chat__messages">
                                <div class="chat__message chat__message--support">
                                    <div class="chat__message-text">Решаем вопрос. Уведомили трейдера, ждем фидбек</div>
                                    <div class="chat__message-time">11:36 AM</div>
                                </div>
                                <div class="chat__message chat__message--support">
                                    <div class="chat__message-text">Ваш банк задержал транзакцию на 19 минут. TRY придут через 10
                                        мин.</div>
                                    <div class="chat__message-time">11:38 AM</div>
                                </div>
                            </div>
                            <div class="chat__messages">
                                <div class="chat__message chat__message--user">
                                    <div class="chat__message-text">ok</div>
                                    <div class="chat__message-time chat__message-time--user">11:40 AM
                                        <div class="chat__message-check">
                                            <img class="chat__message-check-icon" src={CheckMesIcon} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form class="chat__input-container">
                            <button class="chat__input-btn ">
                                <img class="chat__input-icon chat__input-icon--emoji" src={SmileIcon} alt=""/>
                            </button>
                            <textarea class="chat__input" placeholder="Start typing..."></textarea>
                            <button class="chat__input-btn">
                                <img class="chat__input-icon chat__input-icon--mention" src={MentionIcon} alt="" />
                            </button>
                            <button type="submit" class="chat__input-btn">
                                {/*<svg class="chat__input-icon chat__input-icon--send">*/}
                                {/*    <use xlink:href="img/sprite.svg#send"></use>*/}
                                {/*</svg>*/}
                            </button>
                        </form>
                    </div>
                    <div class="footer chat__actions">
                        <button class="chat__button chat__button--reject button button--gray-border">Вопрос не решен</button>
                        <button class="chat__button button">Вопрос решен</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentOrderChat;
