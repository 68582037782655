import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import { useNavigate } from "react-router-dom";
import '../../assets/css/main.css'; // Assuming you have a CSS file for styles

import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../utils/constants';

import HelpIcon from '../../assets/img/svg/help.svg';
import RubIcon from '../../assets/img/currency/rub.svg';
import ArrowIcon from '../../assets/img/svg/arrow.svg';
import LiraIcon from '../../assets/img/currency/lira.svg';
import MastercardIcon from '../../assets/img/card-icons/mastercard.svg';
import Header from "../../components/Header";

function Main() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();


    const [selectedPayment, setSelectedPayment] = useState('card'); // Начальное значение 'card'

    // if (loading || loadingAssets || loadingPriceCurrencies) {
    //     return (
    //         <Loader/>
    //     )
    // }

    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value); // Обновляем состояние на выбранное значение
    };
    return (
        <div class="wrapper">

            <div class="page ">

                <Header/>

                <form class="main client-main">
                    <div class="body">
                        <RouterLink to={'/chat-support'} class="help">
                            <img class="help__icon" src={HelpIcon} alt=""/>
                                <p class="help__text">Помощь</p>
                        </RouterLink>

                        <h1 class="client-main__title title">Отправить перевод</h1>
                        <div class="client-main__form form">
                            <div class="client-main__form-items form__items">
                                <div class="form__item">
                                    <label for="" class="form__label">Отправляете</label>
                                    <button class="form__control form-control form-control--transfer">
                                        <div class="form-control__img form-control__img--currency">
                                            <img class="form-control__icon" src={RubIcon} alt="rub"/>
                                        </div>
                                        <p class="form-control__text">RUB <span class="form-control__text-currency">(Российский рубль)</span>
                                        </p>
                                        <img class="form-control__arrow" src={ArrowIcon} alt="arrow"/>
                                    </button>
                                </div>
                                <div class="form__item">
                                    <input class="form-control form-control--transfer" type="number" value="30000.00"/>
                                    <div class="form__item-bottom">Лимит: 1 000 000.00 RUB</div>
                                </div>
                                <div class="form__item">
                                    <div class="form__item-row">
                                        <label for="" class="form__label">Получаете</label>
                                        <div class="form__converter">Курс: 1 TRY = 3.0 RUB</div>
                                    </div>
                                    <button class="form__control form-control form-control--transfer">
                                        <div class="form-control__img form-control__img--currency">
                                            <img class="form-control__icon" src={LiraIcon} alt="lira"/>
                                        </div>
                                        <p class="form-control__text">TRY <span class="form-control__text-currency">(Турецкая лира)</span>
                                        </p>
                                        <img class="form-control__arrow" src={ArrowIcon} alt="arrow"/>
                                    </button>
                                    <div class="form__item-bottom">Доступно: 5 000 000.00 TRY</div>
                                </div>
                            </div>
                            <div class="client-main__form-received">10 000 TRY</div>
                            <div className="client-main__form-options options">
                                <div className="options__item">
                                    <input
                                        id="card"
                                        type="radio"
                                        name="payment"
                                        className="options__input"
                                        value="card"
                                        checked={selectedPayment === 'card'}
                                        onChange={handlePaymentChange}
                                    />
                                    <label htmlFor="card" className="options__label">Карта</label>
                                </div>
                                <div className="options__item">
                                    <input
                                        id="cash"
                                        type="radio"
                                        name="payment"
                                        className="options__input"
                                        value="cash"
                                        checked={selectedPayment === 'cash'}
                                        onChange={handlePaymentChange}
                                    />
                                    <label htmlFor="cash" className="options__label">Наличными</label>
                                </div>
                            </div>

                            {selectedPayment == 'card' &&

                                <>
                                    <button className="client-main__form-card form-control form-control--transfer">
                                        <img className="form-control__img " src={MastercardIcon} alt="mastercard"/>
                                        <p className="form-control__text">Выбрать карту (**** 8963)</p>
                                        <img className="form-control__arrow" src={ArrowIcon} alt="arrow"/>
                                    </button>
                                    <RouterLink to={'/сhoose-recvisit-type'} class="client-main__form-requisites">Добавить
                                        реквизиты</RouterLink>
                                </>
                            }

                        </div>
                    </div>
                    <div class="footer">
                        {selectedPayment == 'card' ?
                            <RouterLink to={'/payment_step1_loader'} type="submit" class="button">Перевести</RouterLink>
                            :
                            <RouterLink to={'/payment_entering_details_cash'} type="submit" class="button">Оформить перевод</RouterLink>
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Main;
