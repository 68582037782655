import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import { useNavigate } from "react-router-dom";
import '../../../assets/css/main.css'; // Assuming you have a CSS file for styles

import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../../utils/constants';

import LogoIcon from '../../../assets/img/logo.svg'
import PdfIcon from '../../../assets/img/svg/pdf.svg'
import TurkeyIcon from '../../../assets/img/country/turkey.svg'
import ArrowIcon from '../../../assets/img/svg/arrow.svg'
import CityIcon from '../../../assets/img/svg/city.svg'

import Header from "../../../components/Header";

function PaymentEnteringDetailsCash() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();

    // if (loading || loadingAssets || loadingPriceCurrencies) {
    //     return (
    //         <Loader/>
    //     )
    // }

    return (
        <div class="wrapper">

            <div class="page ">

                <Header/>

                <form class="main entering-details-cash">
                    <div class="body">
                        <h1 class="title mb-title">Детали перевода</h1>
                        <div class="entering-details-cash__form form">
                            <div class="entering-details-cash__form-items form__items">
                                <div class="form__item">
                                    <label for="" class="form__label">Страна</label>
                                    <button class="form__control form-control">
                                        <img class="form-control__img " src={TurkeyIcon} alt="" />
                                            <p class="form-control__text">Турция
                                            </p>
                                            <img class="form-control__arrow" src={ArrowIcon} alt="arrow" />
                                    </button>
                                </div>
                                <div class="form__item">
                                    <label for="" class="form__label">Город</label>
                                    <button class="form__control form-control">
                                        <div class="form-control__img form-control__img--city">
                                            <img class="form-control__icon" src={CityIcon} alt=""/>
                                        </div>
                                        <p class="form-control__text">Стамбул</p>
                                        <img class="form-control__arrow" src={ArrowIcon} alt="arrow" />
                                    </button>
                                </div>
                            </div>
                            <div class="entering-details-cash__address">
                                <h2 class="entering-details-cash__address-title">Выберите пункт выдачи</h2>
                                <div class="options options--address">
                                    <div class="options__item">
                                        <input id="address1" type="radio" name="address" class="options__input" checked />
                                            <label for="address1" class="options__label options__label--address">Проспект Бююкдере 18, д.
                                                34</label>
                                    </div>
                                    <div class="options__item">
                                        <input id="address2" type="radio" name="address" class="options__input" />
                                            <label for="address2" class="options__label options__label--address">Serdar-ı Ekrem sokak, Galata 11,
                                                д.
                                                8</label>
                                    </div>
                                    <div class="options__item">
                                        <input id="address3" type="radio" name="address" class="options__input" />
                                            <label for="address3" class="options__label options__label--address">Улица Кеннеди 14, д. 15</label>
                                    </div>
                                    <div class="options__item">
                                        <input id="address4" type="radio" name="address" class="options__input" />
                                            <label for="address4" class="options__label options__label--address">Проспект Бююкдере 18,
                                                д. 34</label>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="footer">
                        <RouterLink to={'/payment_step1_loader'} type="submit" class="button">Далее</RouterLink>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default PaymentEnteringDetailsCash;
