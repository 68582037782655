import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import { useNavigate } from "react-router-dom";
import '../../assets/css/main.css'; // Assuming you have a CSS file for styles

import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../utils/constants';

import Menu1 from '../../assets/img/menu1.svg'
import Menu2 from '../../assets/img/menu2.svg'
import Menu3 from '../../assets/img/menu3.svg'
import Menu4 from '../../assets/img/menu4.svg'
import Logo from '../../assets/img/logo.svg'
import HelpIcon from '../../assets/img/svg/help.svg'
import RubIcon from '../../assets/img/currency/rub.svg'
import ArrowIcon from '../../assets/img/svg/arrow.svg'
import LiraIcon from '../../assets/img/currency/lira.svg'
import MastercardIcon from '../../assets/img/card-icons/mastercard.svg'
import Header from "../../components/Header";

function Main() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();

    // if (loading || loadingAssets || loadingPriceCurrencies) {
    //     return (
    //         <Loader/>
    //     )
    // }

    return (
        <div class="wrapper">
            <div class="page ">

               <Header/>

                <div class="main transactions">
                    <div class="body">
                        <h1 class="transactions__title title mb-title">Мои чаты</h1>
                        <div class="transactions__items">
                            <a href="#" class="transactions__item item-transactions">
                                <div class="item-transactions__data item-transactions__data--order">#457664 </div>
                                <div class="item-transactions__data">12.07.2024 16:30</div>
                                <div class="item-transactions__data ">
                                    <div class="item-transactions__message message-new">
                                        <div class="item-transactions__message-icon"></div>
                                        <span class="item-transactions__message-count">2</span>
                                    </div>
                                </div>
                            </a>
                            <a href="#" class="transactions__item item-transactions">
                                <div class="item-transactions__data item-transactions__data--order">#457664 </div>
                                <div class="item-transactions__data">12.07.2024 16:30</div>
                                <div class="item-transactions__data ">
                                    <div class="item-transactions__message">
                                        <div class="item-transactions__message-icon"></div>
                                        <span class="item-transactions__message-count">15</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="footer">
                        <button type="submit" class="button button--gray-border">Загрузить еще</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;
