import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import { useNavigate } from "react-router-dom";
import '../../assets/css/main.css'; // Assuming you have a CSS file for styles

import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../utils/constants';

import Menu1 from '../../assets/img/menu1.svg'
import Menu2 from '../../assets/img/menu2.svg'
import Menu3 from '../../assets/img/menu3.svg'
import Menu4 from '../../assets/img/menu4.svg'
import Logo from '../../assets/img/logo.svg'
import HelpIcon from '../../assets/img/svg/help.svg'
import RubIcon from '../../assets/img/currency/rub.svg'
import ArrowIcon from '../../assets/img/svg/arrow.svg'
import LiraIcon from '../../assets/img/currency/lira.svg'
import RusIcon from '../../assets/img/country/rus.svg'
import QrIcon from '../../assets/img/qr.jpg'
import MastercardIcon from '../../assets/img/card-icons/mastercard.svg'
import Header from "../../components/Header";

function Settings() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();

    // if (loading || loadingAssets || loadingPriceCurrencies) {
    //     return (
    //         <Loader/>
    //     )
    // }

    return (
        <div class="wrapper">

            <div class="page ">

                <Header/>

                <form class="main settings">
                    <div class="body">
                        <h1 class="title mb-title">Настройки</h1>
                        <div class="settings__form form">
                            <div class="settings__form-items form__items">

                                <div class="form__item">
                                    <label for="" class="form__label">Валюта переводов</label>
                                    <button class="form__control form-control">
                                        <div class="form-control__img form-control__img--currency">
                                            <img class="form-control__icon" src={RubIcon} alt="rub" />
                                        </div>
                                        <p class="form-control__text">RUB <span class="form-control__text-currency">(Российский рубль)</span>
                                        </p>
                                        <img class="form-control__arrow" src={ArrowIcon} alt="arrow" />
                                    </button>
                                </div>
                                <div class="form__item">
                                    <label for="" class="form__label">Язык интерфейса</label>
                                    <button class="form__control form-control">
                                       <img class="form-control__img " src={RusIcon} alt="" />
                                        <p class="form-control__text">РУС
                                        </p>
                                        <img class="form-control__arrow" src={ArrowIcon} alt="arrow" />
                                    </button>
                                </div>
                                <div class="form__item">
                                    <div class="form__item-row">
                                        <label for="" class="form__label">Валюта получения</label>
                                        <div class="form__converter">Курс: 1 TRY = 3.0 RUB</div>
                                    </div>
                                    <button class="form__control form-control">
                                        <div class="form-control__img form-control__img--currency">
                                            <img class="form-control__icon" src={LiraIcon} alt="lira" />
                                        </div>
                                        <p class="form-control__text">TRY <span class="form-control__text-currency">(Турецкая лира)</span></p>
                                        <img class="form-control__arrow" src={ArrowIcon} alt="arrow" />
                                    </button>
                                </div>
                                <RouterLink to={'/my-cards'}  class="button button--blue-border">Мои карты</RouterLink>
                            </div>
                        </div>
                        <div class="settings__code">
                            <img src={QrIcon} alt="" class="settings__code-qr" />
                                <div class="settings__code-content">
                                    <label class="settings__code-label">Код для сделки со <br/> сторонним трейдером:</label>
                                    <a href="#" class="settings__code-link code">
                                        <p class="code__value">120982347</p>
                                        <button class="copy-btn">
                                            {/*<svg class="copy-btn__icon">*/}
                                            {/*    <use xlink:href="img/sprite.svg#copy"></use>*/}
                                            {/*</svg>*/}
                                        </button>
                                    </a>
                                </div>
                        </div>
                    </div>
                    <div class="footer">
                        <button type="submit" class="button">Сохранить изменения</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Settings;
